import App from 'srs.sharedcomponents/lib/esm/App'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { ApplicationInsights, IPageViewTelemetry } from '@microsoft/applicationinsights-web'
import { env } from 'srs.sharedcomponents/lib/esm/api/env'
import { store } from 'srs.sharedcomponents/lib/esm/redux/store'
import RouterProvider from "srs.sharedcomponents/lib/esm/components/context/router/RouterProvider";
import routerConfig from "srs.sharedcomponents/lib/esm/components/config/routes";
import { i18nNamespaces as namespace } from 'srs.sharedcomponents/lib/esm/i18n/i18n'
import coreSrsPrimaryOverride from './i18n/coreSrsPrimaryOverride.json'
import { useTranslation } from 'react-i18next'
import { locales } from 'srs.sharedcomponents/lib/esm/utils/dictionaries'
import { userLanguage } from 'srs.sharedcomponents/lib/esm/redux/slices/userSlice'
import { useAppSelector } from 'srs.sharedcomponents/lib/esm/redux/hooks'

interface IAppWrapper {
    appInsights: ApplicationInsights
}

const AppWrapper = ({ appInsights }: IAppWrapper) => {
    const location = useLocation()
    const userLang = useAppSelector(userLanguage)

    const { i18n } = useTranslation()
	Object.entries(coreSrsPrimaryOverride).forEach(([locale, entity]) =>{
		Object.entries(entity).forEach(([key, value]) => {
			i18n.addResource(locale, namespace.coreSrsPrimary, key, value)
		})
	})

    useEffect(() => {
        const pageInfo: IPageViewTelemetry = {
            name: location?.pathname,
            uri: window?.location.href,
            isLoggedIn: !!store.getState().user?.user?.token
        }
        if (env.APPLICATIONINSIGHTS_CONNECTION_STRING) {
            appInsights.trackPageView(pageInfo)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    useEffect(() => {
        const lang = locales.get(Number(userLang))
        if (lang) {
          i18n.changeLanguage(lang).catch(() => {})
        }
    }, [userLang])

    return (
        <RouterProvider routesConfig={routerConfig}>
            <App />
        </RouterProvider>
    )
}

export default AppWrapper