import { componentsConfig as defaultConfig } from "srs.sharedcomponents/lib/esm/components/config";
import WelcomeBuilder from "srs.sharedcomponents/lib/esm/components/organisms/Welcome/BuilderContent/WelcomeBuilder"
import FooterBuilderComponent from "srs.sharedcomponents/lib/esm/components/organisms/Footer/FooterBuilder/FooterBuilder"

const componentsConfig = { 
    ...defaultConfig, 
    WelcomeContent: WelcomeBuilder,
    FooterContent: FooterBuilderComponent
};

export { componentsConfig };